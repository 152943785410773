<template>
  <div
    class="
      nav-sidebar side-nav
      position-absolute end-0
      mt-nav
    "
  >
    <nav
      class="nav-artwork"
      :class="artworkOpened ? 'expand' : ''"
    >
      <!-- Cities sidebar -->
      <div class="nav-artwork-content bg-primary text-white">
        <a
          class="nav-sidebar-toggler cursor-pointer text-uppercase text-white
          text-decoration-none d-flex justify-content-between align-items-center"
          @click.prevent="toggleArtworksCollapse"
        >
          <h3 class="mb-0"
            :class="artworkOpened ? 'text-secondary' : ''"
          >
            works
          </h3>
          <i
            class="material-icons md-36 nav-sidebar-toggler-icon"
            :class="artworkOpened ? 'rotate text-secondary' : ''"
            >add</i
          >
        </a>
        <!-- Artwork collapsed content -->
        <div class="collapse show mt-3" ref="ArtworkCollapse">
          <router-link
            :to="'/city/'+ citySlug + '/' + 'artist/' + slug + '#artwork1'"
            class="text-uppercase text-decoration-none d-block mb-4"
          >
            <div class="d-flex justify-content-between align-items-start">
              <div class="wrapper">
                <div class="h3">
                  {{ artist.ArtworkTitle }}
                </div>
                <div class="en">
                  {{ artist.Medium }}
                </div>
                <div class="en ">
                  {{ artist.MediumLongDetail }}
                </div>
                <div class="en">
                  {{ artist.Dimension }}
                </div>
                <div class="en ">
                  {{ artist.Year }}
                </div>
              </div>
              <img
                src="../../assets/icon/right-arrow.svg" alt="" class="artwork-link-icon ms-5"
              />
            </div>
          </router-link>
          <router-link
            v-if="artist.ArtworkTitle2"
            :to="'/city/'+ citySlug + '/' + 'artist/' + slug + '#artwork2'"
            class="text-uppercase text-decoration-none d-block mb-4"
          >
            <div class="d-flex justify-content-between">
              <div class="wrapper">
                <div class="h3">
                  {{ artist.ArtworkTitle2 }}
                </div>
                <div class="en">
                  {{ artist.Medium2 }}
                </div>
                <div class="en line-break">
                  {{ artist.MediumLongDetail2 }}
                </div>
                <div class="en">
                  {{ artist.Dimension2 }}
                </div>
                <div class="en ">
                  {{ artist.Year }}
                </div>
              </div>
              <img
                src="../../assets/icon/right-arrow.svg" alt="" class="artwork-link-icon ms-5"
              />
            </div>
          </router-link>
          <router-link
            v-if="artist.ArtworkTitle3"
            :to="'/city/'+ citySlug + '/' + 'artist/' + slug + '#artwork3'"
            class="text-uppercase text-decoration-none d-block mb-4"
          >
            <div class="d-flex justify-content-between">
              <div class="wrapper">
                <div class="h3">
                  {{ artist.ArtworkTitle3 }}
                </div>
                <div class="en">
                  {{ artist.Medium3 }}
                </div>
                <div class="en">
                  {{ artist.MediumLongDetail3 }}
                </div>
                <div class="en">
                  {{ artist.Dimension3 }}
                </div>
                <div class="en ">
                  {{ artist.Year }}
                </div>
              </div>
              <img
                src="../../assets/icon/right-arrow.svg" alt="" class="artwork-link-icon ms-5"
              />
            </div>
          </router-link>
          <router-link
            v-if="artist.ArtworkTitle4"
            :to="'/city/'+ citySlug + '/' + 'artist/' + slug + '#artwork4'"
            class="text-uppercase text-decoration-none d-block mb-4"
          >
            <div class="d-flex justify-content-between">
              <div class="wrapper">
                <div class="h3">
                  {{ artist.ArtworkTitle4 }}
                </div>
                <div class="en">
                  {{ artist.Medium4 }}
                </div>
                <div class="en">
                  {{ artist.MediumLongDetail4 }}
                </div>
                <div class="en">
                  {{ artist.Dimension4 }}
                </div>
                <div class="en ">
                  {{ artist.Year }}
                </div>
              </div>
              <img
                src="../../assets/icon/right-arrow.svg" alt="" class="artwork-link-icon ms-5"
              />
            </div>
          </router-link>
          <router-link
            v-if="artist.ArtworkTitle5"
            :to="'/city/'+ citySlug + '/' + 'artist/' + slug + '#artwork5'"
            class="text-uppercase text-decoration-none d-block"
          >
            <div class="d-flex justify-content-between">
              <div class="wrapper">
                <div class="h3">
                  {{ artist.ArtworkTitle5 }}
                </div>
                <div class="en">
                  {{ artist.Medium5 }}
                </div>
                <div class="en">
                  {{ artist.MediumLongDetail5 }}
                </div>
                <div class="en">
                  {{ artist.Dimension5 }}
                </div>
                <div class="en ">
                  {{ artist.Year }}
                </div>
              </div>
              <img
                src="../../assets/icon/right-arrow.svg" alt="" class="artwork-link-icon ms-5"
              />
            </div>
          </router-link>
          <!-- <h3 class="">
            {{ artist.Year }}
          </h3>
          <h3 class="">
            {{ artist.Medium }}
          </h3>
          <h3>
            {{ artist.MediumLongDetail }}
          </h3>
          <h3 class="">
            {{ artist.Dimension }}
          </h3> -->
        </div>
        <!-- End of  Artwork collapsed content -->
      </div>
    </nav>
  </div>
</template>

<script>
import Collapse from 'bootstrap/js/dist/collapse';
import emitter from '@/components/tools/emitter';

export default {
  props: ['artist', 'city', 'slug'],
  data() {
    return {
      artworkCollapse: '',
      artworkOpened: true,
      citySlug: '',
    };
  },
  methods: {
    toggleArtworksCollapse() {
      this.artworkCollapse.toggle();
      this.artworkOpened = !this.artworkOpened;
      console.log('this.artworkOpened', this.artworkOpened);
    },
  },
  mounted() {
    this.artworkCollapse = new Collapse(this.$refs.ArtworkCollapse, {
      toggle: false,
    });

    // Getting city slug
    emitter.on('emit-city-slug', (data) => {
      this.citySlug = data;
    });

    // Getting scroll status
    emitter.on('toggle-artwork-sidebar', (data) => {
      if (!data) {
        if (this.artworkOpened) {
          this.artworkCollapse.toggle();
          this.artworkOpened = !this.artworkOpened;
          console.log('close nav', this.artworkOpened);
        }
      }
    });
  },
};
</script>
