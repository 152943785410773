<template>
  <div
    ref="slideTextBox"
    class="sliding-holder-bg position-absolute bottom-0 w-100"
    :class="{
      'bg-hongkong-day footer': city === 'HK' && dayTime,
      'bg-hongkong-night footer': city === 'HK' && !dayTime,
      'bg-macau-day footer': city === 'MO' && dayTime,
      'bg-macau-night footer': city === 'MO' && !dayTime,
      'bg-beijing-day footer': city === 'BJ' && dayTime,
      'bg-beijing-night footer': city === 'BJ' && !dayTime,
      'bg-sf-day footer': city === 'SF' && dayTime,
      'bg-sf-night footer': city === 'SF' && !dayTime,
      'bg-tallinn-day footer': city === 'TLL' && dayTime,
      'bg-tallinn-night footer': city === 'TLL' && !dayTime,
      'bg-rome-day footer': city === 'RM' && dayTime,
      'bg-rome-night footer': city === 'RM' && !dayTime,
    }">
    <div
      class="sliding-holder-artwork position-relative w-100 overflow-hidden
      d-flex align-items-center"
    >
      <span
        ref="title"
        class="h4 position-absolute text-uppercase mb-0"
      >
        <template
          v-for="(index) in 4"
          :key="index"
        >
          {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          <template
            v-if="title2"
          >
            {{ title2 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title3"
          >
            {{ title3 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title4"
          >
            {{ title4 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title5"
          >
            {{ title5 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
        </template>
      </span>
      <span
        ref="title-clone"
        class="h4 position-absolute text-uppercase mb-0"
      >
        <template
          v-for="(index) in 4"
          :key="index"
        >
          {{ title1 }}
          <template
            v-if="title2"
          >
            {{ title2 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title3"
          >
            {{ title3 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title4"
          >
            {{ title4 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>

          <template
            v-if="title5"
          >
            {{ title5 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
          <template
            v-else
          >
            {{ title1 }}&nbsp;&nbsp;&nbsp;&nbsp;
          </template>
        </template>
      </span>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  props: ['title1', 'title2', 'title3', 'title4', 'title5', 'day-time', 'city'],
  data() {
    return {
      textWidth: [],
      startPos: [
        {
          start: 0,
          target1: 0,
          target2: 0,
          cloneStart: 0,
        },
      ],
    };
  },
  watch: {
    title5() {
      setTimeout(() => {
        this.initPosition();
        this.loopRender();
      }, 100);
    },
  },
  methods: {
    initPosition() {
      this.textWidth[0] = this.$refs.title.getBoundingClientRect().width;
      this.startPos[0].target1 = -this.textWidth[0];
      [this.startPos[0].target2] = this.textWidth;
      this.startPos[0].cloneStart = this.textWidth;
      this.$refs['title-clone'].style.left = this.formatPos(this.textWidth);
    },
    // start the render loop animation (rAF)
    loopRender() {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => {
          this.render();
        });
      }
    },
    // stop the render loop animation (rAF)
    stopRendering() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    },
    formatPos(pos) {
      return `${pos}px`;
    },
    render() {
      this.requestId = undefined;
      if (this.startPos[0].start <= this.startPos[0].target1) {
        this.startPos[0].start = this.startPos[0].target2;
      } else {
        this.startPos[0].start -= 1;
      }
      if (this.startPos[0].cloneStart <= this.startPos[0].target1) {
        this.startPos[0].cloneStart = this.startPos[0].target2;
      } else {
        this.startPos[0].cloneStart -= 1;
      }
      gsap.set(this.$refs.title, { left: this.formatPos(this.startPos[0].start) });
      gsap.set(this.$refs['title-clone'], { left: this.formatPos(this.startPos[0].cloneStart) });
      this.loopRender();
    },
    overflowShow() {
      this.$refs.box.style.overflow = 'visible';
    },
    overflowHide() {
      this.$refs.box.style.overflow = 'hidden';
    },
  },
  mounted() {
  },
  unmounted() {
    this.stopRendering();
  },
};
</script>
