<template>
  <!-- eslint-disable max-len -->
  <div
    class="row g-0"
    :class="index === 5  ? 'mb-7' : 'mb-3 mb-lg-5'"
  >
    <div class="col-12 col-lg-8 offset-0 offset-lg-2">
      <div
        v-for="(image, index) in artworkImages"
        :key="'image.url' + index"
        class="artwork-img-wrapper w-100
        d-flex flex-column align-items-center mt-5">
        <img
          :src="image.url"
          class="w-100"
          alt=""
          @load="onImgLoad(index)"
        >
        <!-- Image caption for Tallinn artist laura-de-jaeger-and-johannes-luik -->
        <!-- <div
          v-if="slug === 'laura-de-jaeger-and-johannes-luik' && index === 0"
          class="
            row g-0 w-100
            mt-2
          "
        >
          <div class="col-12 px-3 px-lg-0">
            <div class="text-secondary small en">
              <i>Or when we delayed the delineation</i> covers the area between Linnahall and the harbor. This piece of land feels as a transitional area where the functional has yet to be established. A soundscape on the threshold locates the zone where the space can be explored. Walking through the location, a dialogue is created between the visitor moving through the space and the space itself reacting back. The viewer will notice small changes in the space - shapes clinging onto objects. The shapes connect two timelines. They derive from an interest in the city and its reshaping while inhabited. The object's shape is starting from the original like fences, walls and likewise structures, built for their functional purpose. But a disturbance is created by connecting the old to the structure's new shape after being dented (either by accident or on purpose). When connected together, they become spatial. The shapes do not inhabit their original space, but have been slightly relocated from their original position. Creating another potential timeline of something that could happen. The shapes are highlighted with a color which is neither as natural for their surroundings, nor as flashy as the layer of street art covering some of them. They are noticeable as both a part of the space and as a silent intruder - a possible inhabitant of the space.
            </div>
          </div>
        </div> -->
        <div
          v-if="image.caption"
          class="
            row g-0 w-100
            mt-2
          "
        >
          <div class="col-12 col-lg-12">
            <div class="text-secondary small en">
              {{ image.caption }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['slug', 'index', 'images'],
  data() {
    return {
      artworkImages: [],
      artworkImgLoaded: [],
      artworkImgLoadLog: null,
    };
  },
  watch: {
    images() {
      this.artworkImages = this.images;
    },
  },
  methods: {
    onImgLoad(index) {
      const len = this.artworkImages.length;
      this.artworkImgLoadLog.add(index);
      if (this.artworkImgLoadLog.size === len) {
        this.$emit('artworkImgLoadLog', {
          index: this.index,
          length: this.artworkImages.length,
        });
      }
    },
  },
  mounted() {
    this.artworkImgLoadLog = new Set();
  },
};
</script>
