<template>
  <transition
    @leave="leave"
  >
  <!-- v-if="!load" -->
    <Loading
      v-if="!load"
      :overflow="true"
      :load-bg="currentLoadingBg"
    ></Loading>
  </transition>

  <!-- Main content -->
  <ArtworkContent
    :artist="artist[0]"
    :day-time="dayTime"
    :city="currentCity"
    :slug="slug"
    @media-load-complete="mediaLoadComplete"
  ></ArtworkContent>

  <!-- Sliding Text under the hero image -->
  <ArtworkSlidingText
    :title1="artist[0].ArtworkTitle"
    :title2="artist[0].ArtworkTitle2"
    :title3="artist[0].ArtworkTitle3"
    :title4="artist[0].ArtworkTitle4"
    :title5="artist[0].ArtworkTitle5"
    :day-time="dayTime"
    :city="currentCity"
  >
  </ArtworkSlidingText>
  <!-- Sliding Text under the hero image -->

  <!-- Footer -->
  <Footer
    :links="footerLinks"
    :bottom="scrollToBottom"
  ></Footer>
  <!-- Footer -->
</template>

<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import Loading from '@/components/front/LoadingCity.vue';
import ArtworkContent from '@/components/front/ArtworkContent.vue';
import ArtworkSlidingText from '@/components/front/ArtworkSlidingText.vue';
import Footer from '@/components/front/Footer.vue';
import emitter from '@/components/tools/emitter';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      apolloLoad: false,
      mediaLoad: false,
      currentLoadingBg: 'bg-citya-day',
      dayTime: null,
      currentCity: '',
      slug: '',
      artist: [
        {
          city: {
            City: '',
            Slug: '',
            Phyxhibition: false,
          },
          Name: '',
          Slug: '',
          ArtistBio: '',
          ArtistWebsite: '',
          ArtistWebsite2: '',
          AdditionalProjectTitle: '',
          AdditionalProjectDescription: '',
          AdditionalProjectLink: '',
          ArtworkTitle: '',
          ArtworkStatement: '',
          Medium: '',
          MediumLongDetail: '',
          Dimension: '',
          Year: '',
          AudioLink: '',
          LiveStreamingLink: '',
          VideoLink1: '',
          VideoLink2: '',
          VideoLink3: '',
          VideoLink4: '',
          VideoLink5: '',
          VideoLink6: '',
          VideoLink7: '',
          VideoLink8: '',
          VideoLink9: '',
          VideoLink10: '',
          VideoLink11: '',
          VideoLink12: '',
          VideoLink13: '',
          VideoLink14: '',
          VideoLink15: '',
          VideoLink16: '',
          Images: [
            {
              width: '',
              height: '',
              caption: '',
              url: '',
              formats: [],
            },
          ],
          ArtworkTitle2: '',
          ArtworkStatement2: '',
          Medium2: '',
          MediumLongDetail2: '',
          Dimension2: '',
          Year2: '',
          Artwork2VieoLink1: '',
          Artwork2VideoLink2: '',
          Artwork2VideoLink3: '',
          Artwork2VideoLink4: '',
          Artwork2VideoLink5: '',
          Artwork2Images: [
            {
              width: '',
              height: '',
              caption: '',
              url: '',
              formats: [],
            },
          ],
          ArtworkTitle3: '',
          ArtworkStatement3: '',
          Medium3: '',
          MediumLongDetail3: '',
          Dimension3: '',
          Year3: '',
          Artwork3VieoLink1: '',
          Artwork3VideoLink2: '',
          Artwork3VideoLink3: '',
          Artwork3VideoLink4: '',
          Artwork3VideoLink5: '',
          Artwork3Images: [
            {
              width: '',
              height: '',
              caption: '',
              url: '',
              formats: [],
            },
          ],
          ArtworkTitle4: '',
          ArtworkStatement4: '',
          Medium4: '',
          MediumLongDetail4: '',
          Dimension4: '',
          Year4: '',
          Artwork4VieoLink1: '',
          Artwork4VideoLink2: '',
          Artwork4VideoLink3: '',
          Artwork4VideoLink4: '',
          Artwork4VideoLink5: '',
          Artwork4Images: [
            {
              width: '',
              height: '',
              caption: '',
              url: '',
              formats: [],
            },
          ],
          ArtworkTitle5: '',
          ArtworkStatement5: '',
          Medium5: '',
          MediumLongDetail5: '',
          Dimension5: '',
          Year5: '',
          Artwork5VieoLink1: '',
          Artwork5VideoLink2: '',
          Artwork5VideoLink3: '',
          Artwork5VideoLink4: '',
          Artwork5VideoLink5: '',
          Artwork5Images: [
            {
              width: '',
              height: '',
              caption: '',
              url: '',
              formats: [],
            },
          ],
          YoungCurrent: false,
        },
      ],
      footerLinks: [],
      scrollToBottom: false,
      firstScroll: true,
      scrollOverHeight: false,
    };
  },
  computed: {
    load() {
      console.log(`apollo status: ${this.apolloLoad}, media status: ${this.mediaLoad}`);
      return this.apolloLoad && this.mediaLoad;
    },
  },
  watch: {
    artist: {
      deep: true,
      handler() {
        // Emit city slug to side bar
        emitter.emit('emit-city-slug', this.artist[0].city.Slug);

        // update footer link
        const linkData1 = {
          City: this.artist[0].city.City,
          Text: `BACK TO ${this.artist[0].city.City}`,
          Link: `/city/${this.artist[0].city.Slug}`,
        };
        const linkData2 = {
          City: this.artist[0].city.City,
          Text: `E-XHIBITION | ${this.artist[0].city.City}`,
          Link: `/city/${this.artist[0].city.Slug}/artists`,
        };
        this.footerLinks.push(linkData1, linkData2);
        if (this.artist[0].city.City === 'Hong Kong') {
          const linkData3 = {
            City: this.artist[0].city.City,
            Text: `YOUTH CURRENT | ${this.artist[0].city.City}`,
            Link: `/city/${this.artist[0].city.Slug}/youth-current-artists`,
          };
          this.footerLinks.push(linkData3);
        }
        if (this.artist[0].city.Phyxhibition) {
          const linkData4 = {
            City: this.artist[0].city.City,
            Text: `PHY-XHIBITION | ${this.artist[0].city.City}`,
            Link: `/city/${this.artist[0].city.Slug}/phy-xhibition`,
          };
          this.footerLinks.push(linkData4);
        }
      },
    },
  },
  apollo: {
    artist: {
      query: gql`
        query Artist($Slug: String!) {
          artists(where: { Slug: $Slug }) {
            city {
              City
              Slug
              Phyxhibition
            }
            Name
            Slug
            ArtistBio
            ArtistWebsite
            ArtistWebsite2
            AdditionalProjectTitle
            AdditionalProjectDescription
            AdditionalProjectLink
            ArtworkTitle
            ArtworkStatement
            Medium
            MediumLongDetail
            Dimension
            Year
            Images {
              width
              height
              caption
              url
              formats
            }
            AudioLink
            LiveStreamingLink
            VideoLink1
            VideoLink2
            VideoLink3
            VideoLink4
            VideoLink5
            VideoLink6
            VideoLink7
            VideoLink8
            VideoLink9
            VideoLink10
            VideoLink11
            VideoLink12
            VideoLink13
            VideoLink14
            VideoLink15
            VideoLink16
            ArtworkTitle2
            ArtworkStatement2
            Medium2
            MediumLongDetail2
            Dimension2
            Year2
            Artwork2VideoLink1
            Artwork2VideoLink2
            Artwork2VideoLink3
            Artwork2VideoLink4
            Artwork2VideoLink5
            Artwork2Images {
              width
              height
              caption
              url
              formats
            }
            ArtworkTitle3
            ArtworkStatement3
            Medium3
            MediumLongDetail3
            Dimension3
            Year3
            Artwork3VideoLink1
            Artwork3VideoLink2
            Artwork3VideoLink3
            Artwork3VideoLink4
            Artwork3VideoLink5
            Artwork3Images {
              width
              height
              caption
              url
              formats
            }
            ArtworkTitle4
            ArtworkStatement4
            Medium4
            MediumLongDetail4
            Dimension4
            Year4
            Artwork4VideoLink1
            Artwork4VideoLink2
            Artwork4VideoLink3
            Artwork4VideoLink4
            Artwork4VideoLink5
            Artwork4Images {
              width
              height
              caption
              url
              formats
            }
            ArtworkTitle5
            ArtworkStatement5
            Medium5
            MediumLongDetail5
            Dimension5
            Year5
            Artwork5VideoLink1
            Artwork5VideoLink2
            Artwork5VideoLink3
            Artwork5VideoLink4
            Artwork5VideoLink5
            Artwork5Images {
              width
              height
              caption
              url
              formats
            }
            YoungCurrent
          }
        }
      `,
      variables() {
        return {
          Slug: this.$route.params.slug,
        };
      },
      update(data) {
        return data.artists;
      },
      error(error) {
        console.error("We've got an error!", error);
      },
      loadingKey: 'loadingQueriesCount',
      // watchLoading will be called whenever the loading state changes
      // eslint-disable-next-line no-unused-vars
      watchLoading(isLoading, countModifier) {
        // isLoading is a boolean
        // countModifier is either 1 or -1

        this.apolloLoad = false;
        if (this.currentLoadingBg !== 'bg-citya-day' && isLoading) {
          this.updateLoadBg();
        }
        if (!isLoading) {
          this.apolloLoad = true;
          console.log('load complete');
        }
      },
    },
  },
  components: {
    Loading,
    ArtworkContent,
    ArtworkSlidingText,
    Footer,
  },
  methods: {
    /**
     * Page Load effects
     */
    updateLoadBg() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case 'HKArtist':
          this.currentLoadingBg = 'bg-hongkong-day';
          break;
        case 'MOArtist':
          this.currentLoadingBg = 'bg-macau-day';
          break;
        case 'BJArtist':
          this.currentLoadingBg = 'bg-beijing-day';
          break;
        case 'SFArtist':
          this.currentLoadingBg = 'bg-sf-day';
          break;
        case 'TLLArtist':
          this.currentLoadingBg = 'bg-tallinn-day';
          break;
        case 'RMArtist':
          this.currentLoadingBg = 'bg-rome-day';
          break;
        default:
          this.currentLoadingBg = 'bg-citya-day';
          break;
      }
    },
    mediaLoadComplete() {
      console.log('media load complete');
      this.mediaLoad = true;
    },
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1.5,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
      setTimeout(() => {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('overflow-hidden');
      }, 1500);
    },
    /**
     * Main methods
     */
    getCurrentCity() {
      [this.currentCity] = this.$route.name.split('A');
    },
    footerColor() {
      if (this.currentHour > 6 && this.currentHour < 18) {
        this.dayTime = true;
      } else {
        this.dayTime = false;
      }
    },
    onScroll() {
      const windowY = window.scrollY;
      const intViewportHeight = window.innerHeight;
      // console.log(windowY, windowY + intViewportHeight);

      // Scrolled to Battom
      if (windowY + intViewportHeight >= document.documentElement.offsetHeight) {
        this.scrollToBottom = true;
      } else {
        this.scrollToBottom = false;
      }

      // Scroll over viewport height
      if (windowY > intViewportHeight) {
        this.scrollOverHeight = true;

        if (this.firstScroll) {
          this.firstScroll = false;
        } else {
          emitter.emit('toggle-artwork-sidebar', false);
        }
      }
    },
  },
  mounted() {
    // console.clear();
    /**
     * Handling loading event
     */
    this.updateLoadBg();
    /**
     * Handling background color
     */
    console.log('this.$store.state = ', this.$store.state.time);
    if (this.$store.state.time === 'day') {
      this.dayTime = true;
    } else {
      this.dayTime = false;
    }
    this.slug = this.$route.params.slug;

    // changing bg color to black
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-primary');

    this.getCurrentCity();
    // getting day night data for footer
    emitter.on('emit-hour', (data) => {
      this.currentHour = data;
      // Footer color will change based on which city the artist represent
      this.footerColor();
    });

    window.addEventListener('scroll', this.onScroll);
  },
  unmounted() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-primary');
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>
