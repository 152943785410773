/* eslint-disable max-len */
/* eslint-disable max-len */
<template>
  <ArtworkSideBar
    :artist="artist"
    :city="city"
    :slug="slug"
  ></ArtworkSideBar>
  <!-- Hero Session -->
  <div
    ref="hero"
    class="hero-wrapper"
  >
    <!-- title -->
    <div
      class="container-fluid d-flex flex-column justify-content-between position-absolute
      top-0 mt-nav">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
          <h1 class="text-uppercase">
            {{ artist.Name }}
          </h1>
        </div>
      </div>
    </div>
    <!-- title -->

    <!-- hero image -->
    <img
    :src="artist.Images[0].url"
    alt=""
    class="w-100 h-100 img-object-fit" />
    <!-- hero image -->
  </div>
  <!-- End of Hero Session -->

  <!-- Artist Bio and Project Description -->
  <div class="container-fluid">
    <div class="row mt-7 mt-lg-5 g-0">
      <div class="col-12 col-lg-8 offset-0 offset-lg-2">
        <h1 class="h1-xs-small text-uppercase text-center">
          about {{ artist.Name }}
        </h1>
        <p class="h3 h3-xs-small body-regular
        line-break
        mt-4">
          {{ artist.ArtistBio }}
        </p>
        <div
          v-if="artist.ArtistWebsite"
          class="w-100 text-center mt-3">
          <a
            :href="artist.ArtistWebsite"
            target="_blank"
            class="btn btn-xs-w-100"
            :class="{
              'bg-hongkong-day': city === 'HK' && dayTime,
              'bg-hongkong-night': city === 'HK' && !dayTime,
              'bg-macau-day': city === 'MO' && dayTime,
              'bg-macau-night': city === 'MO' && !dayTime,
              'bg-beijing-day': city === 'BJ' && dayTime,
              'bg-beijing-night': city === 'BJ' && !dayTime,
              'bg-sf-day': city === 'SF' && dayTime,
              'bg-sf-night': city === 'SF' && !dayTime,
              'bg-tallinn-day': city === 'TLL' && dayTime,
              'bg-tallinn-night': city === 'TLL' && !dayTime,
              'bg-rome-day': city === 'RM' && dayTime,
              'bg-rome-night': city === 'RM' && !dayTime,
            }"
          >
            <div class="d-flex align-items-center justify-content-between
            body-regular text-uppercase">
              <span class="h4 mb-0">artist website</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-5" />
            </div>
          </a>
        </div>
        <div
          v-if="artist.ArtistWebsite2"
          class="w-100 text-center mt-3"
        >
          <a
            :href="artist.ArtistWebsite2"
            target="_blank"
            class="btn btn-xs-w-100"
            :class="{
              'bg-hongkong-day': city === 'HK' && dayTime,
              'bg-hongkong-night': city === 'HK' && !dayTime,
              'bg-macao-day': city === 'MO' && dayTime,
              'bg-macau-night': city === 'MO' && !dayTime,
              'bg-beijing-day': city === 'BJ' && dayTime,
              'bg-beijing-night': city === 'BJ' && !dayTime,
              'bg-sf-day': city === 'SF' && dayTime,
              'bg-sf-night': city === 'SF' && !dayTime,
              'bg-tallinn-day': city === 'TLL' && dayTime,
              'bg-tallinn-night': city === 'TLL' && !dayTime,
              'bg-rome-day': city === 'RM' && dayTime,
              'bg-rome-night': city === 'RM' && !dayTime,
            }"
          >
            <div class="d-flex align-items-center justify-content-between
            body-regular text-uppercase">
              <span class="h4 mb-0">artist website</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-5" />
            </div>
          </a>
        </div>
        <h1
          v-if="artist.AdditionalProjectTitle"
          class="h1-xs-small text-uppercase text-center mt-5"
        >
          about {{ artist.AdditionalProjectTitle }}
        </h1>
        <p
          v-if="artist.AdditionalProjectDescription"
          class="h3 h3-xs-small body-regular
          line-break
          mt-4"
        >
          {{ artist.AdditionalProjectDescription }}
        </p>
        <div
          v-if="artist.AdditionalProjectLink"
          class="w-100 text-center mt-3"
        >
          <a
            :href="artist.AdditionalProjectLink"
            target="_blank"
            class="btn"
            :class="{
              'bg-hongkong-day': city === 'HK' && dayTime,
              'bg-hongkong-night': city === 'HK' && !dayTime,
              'bg-macau-day': city === 'MO' && dayTime,
              'bg-macau-day': city === 'MO' && !dayTime,
              'bg-beijing-day': city === 'BJ' && dayTime,
              'bg-beijing-day': city === 'BJ' && !dayTime,
              'bg-sf-day': city === 'SF' && dayTime,
              'bg-sf-day': city === 'SF' && !dayTime,
              'bg-tallinn-day': city === 'TLL' && dayTime,
              'bg-tallinn-day': city === 'TLL' && !dayTime,
              'bg-rome-day': city === 'RM' && dayTime,
              'bg-rome-day': city === 'RM' && !dayTime,
            }"
          >
            <div class="d-flex align-items-center body-regular text-uppercase">
              <span class="h4 mb-0">view the project</span>
              <img src="../../assets/icon/right-arrow.svg" alt="" class="link-icon ms-5" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Artist Bio and Project Description -->

  <!-- Artwork 1 -->
  <ArtworkContentLabel
    v-if="artist.ArtworkTitle"
    id="artwork1"
    :title="artist.ArtworkTitle"
    :medium="artist.Medium"
    :medium-long="artist.MediumLongDetail"
    :dimension="artist.Dimension"
    :year="artist.Year"
    :statement="artist.ArtworkStatement"
  >
  </ArtworkContentLabel>
  <!-- Artwork 1 Video Swiper Slider -->
  <ArtworkContentVideos
    v-if="artist.VideoLink1"
    :index="1"
    :videos="artworksVideos[0]"
    @artwork-video-load-log="artworkVideoLoadLog"
  >
  </ArtworkContentVideos>
  <!-- End of Artwork 1 Video Swiper Slider -->

  <!-- Artwork 1 Audio -->
  <ArtworkContentAudio
    v-if="artist.AudioLink"
    :index="1"
    :link="artist.AudioLink"
  >
  </ArtworkContentAudio>
  <!-- End of Artwork 1 Audio -->

  <!-- VR scene embed for Kalen work -->
  <div
    v-if="slug === 'lee-wing-ki-kalen'"
    class="row g-0"
  >
    <div class="col-12 col-lg-8 offset-0 offset-lg-2">
      <div
        class="vr-wrapper overflow-hidden position-relative"
      >
        <div
          class="bg-primary opacity-85 d-block d-lg-none position-absolute
          w-100 h-100 d-flex justify-content-center align-items-center"
        >
          <div class="h4 text-uppercase px-6 text-center">
            VR content is <u>recommended</u> in the desktop version and optimized for viewing on Google Chrome
          </div>
        </div>
        <iframe
          src="https://hubs.mozilla.com/NxTWL5F?embed_token=5f6290b9ab1065abfdca3117ad786f1a"
          allow="microphone; camera; vr; speaker; xr-spatial-tracking"
        >
        </iframe>
      </div>
      <div
        class="
          text-secondary
          d-none d-lg-block
          text-center text-lg-start
          small en
          mt-2 px-3 px-lg-0
        "
      >
        VR content is <u>recommended</u> in the desktop version and optimized for viewing on Google Chrome
      </div>
    </div>
  </div>
  <!-- <video id="video" loop crossOrigin="anonymous" playsinline style="display:none">
    <source
      :src="`${this.publicPath}textures/video/Kalen-texture-01.mp4`"
      type='video/mp4;'>
  </video> -->
  <!-- VR scene embed for Kalen work -->

  <!-- Artwork 1 Images -->
  <ArtworkContentImages
    :index="1"
    :slug="slug"
    :images="artist.Images"
    @artwork-img-load-log="artworkImgLoadLog"
  ></ArtworkContentImages>
  <!-- End of Artwork 1 Images -->
  <!-- End of Artwork 1 -->

  <!-- Artwork 2 -->
  <ArtworkContentLabel
    v-if="artist.ArtworkTitle2"
    id="artwork2"
    :title="artist.ArtworkTitle2"
    :medium="artist.Medium2"
    :medium-long="artist.MediumLongDetail2"
    :dimension="artist.Dimension2"
    :year="artist.Year2"
    :statement="artist.ArtworkStatement2"
  >
  </ArtworkContentLabel>
  <!-- Artwork 2 Video Swiper Slider -->
  <ArtworkContentVideos
    v-if="artist.Artwork2VideoLink1"
    :index="2"
    :videos="artworksVideos[1]"
    @artwork-video-load-log="artworkVideoLoadLog"
  >
  </ArtworkContentVideos>
  <!-- End of Artwork 2 Video Swiper Slider -->

  <!-- Artwork 2 Images -->
  <ArtworkContentImages
    v-if="artist.Artwork2Images"
    :index="2"
    :images="artist.Artwork2Images"
    @artwork-img-load-log="artworkImgLoadLog"
  ></ArtworkContentImages>
  <!-- End of Artwork 2 Images -->
  <!-- End of Artwork 2 -->

  <!-- Artwork 3 -->
  <ArtworkContentLabel
    v-if="artist.ArtworkTitle3"
    id="artwork3"
    :title="artist.ArtworkTitle3"
    :medium="artist.Medium3"
    :medium-long="artist.MediumLongDetail3"
    :dimension="artist.Dimension3"
    :year="artist.Year3"
    :statement="artist.ArtworkStatement3"
  >
  </ArtworkContentLabel>
  <!-- Artwork 3 Video Swiper Slider -->
  <ArtworkContentVideos
    v-if="artist.Artwork3VideoLink1"
    :index="3"
    :videos="artworksVideos[2]"
    @artwork-video-load-log="artworkVideoLoadLog"
  >
  </ArtworkContentVideos>
  <!-- End of Artwork 3 Video Swiper Slider -->

  <!-- Artwork 3 Images -->
  <ArtworkContentImages
    v-if="artist.Artwork3Images"
    :index="3"
    :images="artist.Artwork3Images"
    @artwork-img-load-log="artworkImgLoadLog"
  ></ArtworkContentImages>
  <!-- End of Artwork 3 Images -->
  <!-- End of Artwork 3 -->

  <!-- Artwork 4 -->
  <ArtworkContentLabel
    v-if="artist.ArtworkTitle4"
    id="artwork4"
    :title="artist.ArtworkTitle4"
    :medium="artist.Medium4"
    :medium-long="artist.MediumLongDetail4"
    :dimension="artist.Dimension4"
    :year="artist.Year4"
    :statement="artist.ArtworkStatement4"
  >
  </ArtworkContentLabel>
  <!-- Artwork 4 Video Swiper Slider -->
  <ArtworkContentVideos
    v-if="artist.Artwork4VideoLink1"
    :index="4"
    :videos="artworksVideos[3]"
    @artwork-video-load-log="artworkVideoLoadLog"
  >
  </ArtworkContentVideos>
  <!-- End of Artwork 4 Video Swiper Slider -->

  <!-- Artwork 4 Images -->
  <ArtworkContentImages
    v-if="artist.Artwork4Images"
    :index="4"
    :images="artist.Artwork4Images"
    @artwork-img-load-log="artworkImgLoadLog"
  ></ArtworkContentImages>
  <!-- End of Artwork 4 Images -->
  <!-- End of Artwork 4 -->

  <!-- Artwork 5 -->
  <ArtworkContentLabel
    v-if="artist.ArtworkTitle5"
    id="artwork5"
    :title="artist.ArtworkTitle5"
    :medium="artist.Medium5"
    :medium-long="artist.MediumLongDetail5"
    :dimension="artist.Dimension5"
    :year="artist.Year5"
    :statement="artist.ArtworkStatement5"
  >
  </ArtworkContentLabel>
  <!-- Artwork 5 Video Swiper Slider -->
  <ArtworkContentVideos
    v-if="artist.Artwork5VideoLink1"
    :index="5"
    :videos="artworksVideos[4]"
    @artwork-video-load-log="artworkVideoLoadLog"
  >
  </ArtworkContentVideos>
  <!-- End of Artwork 5 Video Swiper Slider -->

  <!-- Artwork 5 Images -->
  <ArtworkContentImages
    v-if="artist.Artwork5Images"
    :index="5"
    :images="artist.Artwork5Images"
    @artwork-img-load-log="artworkImgLoadLog"
  ></ArtworkContentImages>
  <!-- End of Artwork 5 Images -->
  <!-- End of Artwork 5 -->
</template>

<script>
// eslint-disable-next-line no-unused-vars
import emitter from '@/components/tools/emitter';
import ArtworkSideBar from '@/components/front/ArtworkSideBar.vue';
import ArtworkContentLabel from '@/components/front/ArtworkContentLabel.vue';
import ArtworkContentVideos from '@/components/front/ArtworkContentVideos.vue';
import ArtworkContentAudio from '@/components/front/ArtworkContentAudio.vue';
import ArtworkContentImages from '@/components/front/ArtworkContentImages.vue';

export default {
  props: ['artist', 'day-time', 'city', 'slug'],
  emits: ['mediaLoadComplete'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      artworkAmount: 1,
      imgLoadLog: [],
      imgLoadStatus: false,
      videoLoadLog: [],
      videoLoadStatus: false,
      mediaLoadStatus: false,
      artworksVideos: [],
      artworkVideosNum: 0,
      sizes: {
        width: 0,
        height: 0,
      },
    };
  },
  components: {
    ArtworkSideBar,
    ArtworkContentLabel,
    ArtworkContentVideos,
    ArtworkContentAudio,
    ArtworkContentImages,
  },
  watch: {
    artist: {
      deep: true,
      handler() {
        // Count number of works
        for (let i = 2; i < 6; i += 1) {
          if (this[`ArtworkTitle${i}`]) {
            this.artworkAmount += 1;
          }
        }

        // Handle Video
        let artworkVideoArr = [];
        // eslint-disable-next-line max-len
        for (let i = 0; i < 16; i += 1) {
          const videoName = `VideoLink${i + 1}`;
          // console.log(this.artist[videoName]);
          if (this.artist[videoName]) {
            artworkVideoArr.push(this.artist[videoName]);
          }
        }
        this.artworksVideos.push(artworkVideoArr);

        artworkVideoArr = [];
        for (let i = 0; i < 5; i += 1) {
          const videoName = `Artwork2VideoLink${i + 1}`;
          // console.log(this.artist[videoName]);
          if (this.artist[videoName]) {
            artworkVideoArr.push(this.artist[videoName]);
          }
        }
        this.artworksVideos.push(artworkVideoArr);

        artworkVideoArr = [];
        for (let i = 0; i < 5; i += 1) {
          const videoName = `Artwork3VideoLink${i + 1}`;
          // console.log(this.artist[videoName]);
          if (this.artist[videoName]) {
            artworkVideoArr.push(this.artist[videoName]);
          }
        }
        this.artworksVideos.push(artworkVideoArr);

        artworkVideoArr = [];
        for (let i = 0; i < 5; i += 1) {
          const videoName = `Artwork4VideoLink${i + 1}`;
          // console.log(this.artist[videoName]);
          if (this.artist[videoName]) {
            artworkVideoArr.push(this.artist[videoName]);
          }
        }
        this.artworksVideos.push(artworkVideoArr);

        artworkVideoArr = [];
        for (let i = 0; i < 5; i += 1) {
          const videoName = `Artwork5VideoLink${i + 1}`;
          // console.log(this.artist[videoName]);
          if (this.artist[videoName]) {
            artworkVideoArr.push(this.artist[videoName]);
          }
        }
        this.artworksVideos.push(artworkVideoArr);

        // Count number of works with video link
        for (let i = 0; i < 5; i += 1) {
          if (this.artworksVideos[i].length > 0) {
            this.artworkVideosNum += 1;
          }
        }
      },
    },
  },
  methods: {
    updatesizeMode() {
      // Update sizes
      this.sizes.width = window.innerWidth;
      this.sizes.height = window.innerHeight;
      if (this.sizes.width <= 575.99) {
        this.sizeMode = 'mobile';
      } else if (this.sizes.width <= 1199.99) {
        this.sizeMode = 'tablet';
      } else {
        this.sizeMode = 'desktop';
      }
    },
    adjustViewportHeight() {
      this.$refs.hero.style.height = `${this.sizes.height}px`;
    },
    reportWindowSize() {
      if (this.sizeMode === 'desktop') {
        this.updatesizeMode();
        this.adjustViewportHeight();
      }
    },
    artworkImgLoadLog({ index, length }) {
      this.imgLoadLog.add(index);
      // eslint-disable-next-line max-len
      console.log(`Artwork ${index}, ${length} image load completed`, this.imgLoadLog.size === parseInt(this.artworkAmount, 10));
      if (this.imgLoadLog.size === parseInt(this.artworkAmount, 10)) {
        console.log('All images loaded');
        console.log('artworkVideosNum', this.artworkVideosNum);
        this.imgLoadStatus = true;

        // check if both images and videos all loaded
        if (this.artworkVideosNum > 0) {
          console.log('this.artworkVideosNum > 0', this.imgLoadStatus && this.videoLoadStatus);
          if (this.imgLoadStatus && this.videoLoadStatus) {
            this.mediaLoadStatus = true;
            console.log('media load complete');
            // emitter.emit('mediaLoadComplete');
            this.$emit('mediaLoadComplete');
          }
        } else if (this.imgLoadStatus) {
          this.mediaLoadStatus = true;
          console.log('media load complete');
          // emitter.emit('mediaLoadComplete');
          this.$emit('mediaLoadComplete');
        }
      }
    },
    artworkVideoLoadLog({ index, length }) {
      console.log(`Artwork ${index}, ${length} video load completed`);
      this.videoLoadLog.add(index);
      if (this.videoLoadLog.size === this.artworkVideosNum) {
        console.log('All Videos loaded');
        this.videoLoadStatus = true;
        // check if both images and videos all loaded
        if (this.imgLoadStatus && this.videoLoadStatus) {
          this.mediaLoadStatus = true;
          console.log('media load complete');
          // emitter.emit('mediaLoadComplete');
          this.$emit('mediaLoadComplete');
        }
      }
    },
  },
  mounted() {
    this.updatesizeMode();
    this.adjustViewportHeight();
    window.addEventListener('resize', this.reportWindowSize);
    this.imgLoadLog = new Set();
    this.videoLoadLog = new Set();
  },
  unmounted() {
    window.removeEventListener('resize', this.reportWindowSize);
  },
};
</script>
