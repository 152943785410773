<template>
  <div class="row g-0">
    <div class="col-12 col-lg-8 offset-0 offset-lg-2">
      <div
        class="artwork-video-wrapper d-flex mt-5 mb-5 mb-lg-7"
      >
        <button class="btn p-0 p-lg-3" @click="prev">
          <i class="material-icons md-48">chevron_left</i>
        </button>
        <swiper
          :loop="true"
          :breakpoints="swiperBreakpoints.breakpoints"
          @swiper="onSwiper"
        >
          <template
            v-for="(video, index) in videos"
            :key="video + index"
          >
            <swiper-slide
              v-if="video !== null"
            >
              <iframe
                :src="'https://player.vimeo.com/video/' + video"
                width="100%" height="100%"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                @load="onVideoLoad(index)"
              >
              </iframe>
            </swiper-slide>
          </template>
        </swiper>
        <button class="btn p-0 p-lg-3" @click="next">
          <i class="material-icons md-48">chevron_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper and modules
import SwiperCore, { Navigation } from 'swiper/core';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation]);

export default {
  props: ['index', 'videos'],
  data() {
    return {
      swiper: '',
      artworkVideosNum: 0,
      artworkVideoLoadLog: null,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const swiperBreakpoints = reactive({
      breakpoints: {
        575: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        991: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1190: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1430: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
    return {
      props,
      swiperBreakpoints,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    prev() {
      this.swiper.slidePrev();
    },
    next() {
      this.swiper.slideNext();
    },
    onVideoLoad(index) {
      this.artworkVideosNum = this.videos.length;
      this.artworkVideoLoadLog.add(index);
      if (this.artworkVideoLoadLog.size === this.artworkVideosNum) {
        this.$emit('artworkVideoLoadLog', {
          index: this.index,
          length: this.videos.length,
        });
      }
    },
  },
  mounted() {
    this.artworkVideoLoadLog = new Set();
  },
};
</script>
