<template>
  <div class="container-fluid">
    <div class="row mt-3 mt-lg-5 g-0">
      <div class="col-12 col-lg-8 offset-0 offset-lg-2">
        <h1
          class="h1-xs-small text-uppercase text-center mt-5"
        >
          {{ title }}
        </h1>
        <h2
          v-if="medium"
          class="body-regular h3 mt-3"
        >
          {{ medium }}
        </h2>
        <h2
          v-if="mediumLong"
          class="body-regular line-break h3"
        >
          {{ mediumLong }}
        </h2>
        <h2
          v-if="dimension"
          class="body-regular h3"
        >
          {{ dimension }}
        </h2>
        <h3
          class="body-regular"
        >
          {{ year }}
        </h3>
        <h2
          v-if="statement"
          class="body-regular h3 h3-xs-small
          line-break
          mt-3"
        >
          {{ statement }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'medium', 'medium-long', 'dimension', 'year', 'statement'],
};
</script>
