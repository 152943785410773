<template>
  <div class="row g-0">
    <div class="col-12 col-lg-8 offset-0 offset-lg-2">
      <div
        class="
          text-center
          mt-5 mb-5 mb-lg-7
        "
      >
        <audio controls>
          <source
            :src="link"
            type="audio/mpeg"
          >
        Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['index', 'link'],
};
</script>
