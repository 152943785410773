<template>
  <div
    class="footer-navbar position-fixed bottom-0 left-0
    bg-primary text-white w-100"
  >
    <a
      class="cursor-pointer p-3
      d-flex flex-column justify-content-center align-items-center"
      @click.prevent="toggleFooterCollapse"
    >
      <!-- Folded footer icon -->
      <hr class="footer-line m-0" />
      <!-- Folded footer icon -->
    </a>
    <!-- Open footer session -->
    <div
      class="collapse"
      ref="FooterCollapse"
    >
      <div class="wrapper d-flex flex-column align-items-center">
        <router-link
          v-for="(link, index) in links"
          :key="link.Link"
          :to="link.Link"
          class="text-uppercase body-regular text-white text-decoration-none"
          :class="{
            'h3' : index > 0,
            'my-2 my-lg-3' : index === 0,
            'mb-3' : index === links.length - 1}"
        >
          {{ link.Text }}
        </router-link>
      </div>
    </div>
    <!-- Open footer session -->
  </div>
</template>

<script>
import Collapse from 'bootstrap/js/dist/collapse';

export default {
  props: ['links', 'bottom'],
  data() {
    return {
      footerCollapse: '',
      footerOpened: false,
    };
  },
  watch: {
    bottom() {
      if (this.bottom) {
        // expand footer automatically
        if (!this.footerOpened) {
          this.footerCollapse.toggle();
          this.footerOpened = !this.footerOpened;
        } else {
          this.footerCollapse.toggle();
          this.footerOpened = !this.footerOpened;
        }
      } else if (!this.bottom) {
        // collapse footer automatically
        if (this.footerOpened) {
          this.footerCollapse.toggle();
          this.footerOpened = !this.footerOpened;
        }
      }
    },
  },
  methods: {
    toggleFooterCollapse() {
      this.footerCollapse.toggle();
      this.footerOpened = !this.footerOpened;
    },
  },
  mounted() {
    this.footerCollapse = new Collapse(this.$refs.FooterCollapse, {
      toggle: false,
    });
  },
};
</script>
